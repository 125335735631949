export const LOGIN_REQUEST_SENT = "LOGIN_REQUEST_SENT";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const REGISTRATION_START = "REGISTRATION_START";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

export const LOGOUT_REQUEST_SENT = "LOGOUT_REQUEST_SENT";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const LOGOUT_REQUEST_FAIL = "LOGOUT_REQUEST_FAIL";
export const CLEAR_ERROR ="CLEAR_ERROR"

export const GET_CATEGORY_LIST_START="GET_CATEGORY_LIST_START"
export const GET_CATEGORY_LIST_SUCCESS="GET_CATEGORY_LIST_SUCCESS"
export const GET_CATEGORY_LIST_FAIL="GET_CATEGORY_LIST_FAIL"

export const GET_AUTH_BRANDS_START="GET_AUTH_BRANDS_START"
export const GET_AUTH_BRANDS_SUCCESS="GET_AUTH_BRANDS_SUCCESS"
export const GET_AUTH_BRANDS_FAIL="GET_AUTH_BRANDS_FAIL"


export const GET_CATEGORY_ATTRIBUTES="GET_CATEGORY_ATTRIBUTES"
export const GET_CATEGORY_ATTRIBUTES_SUCCESS="GET_CATEGORY_ATTRIBUTES_SUCCESS"
export const GET_CATEGORY_ATTRIBUTES_FAIL="GET_CATEGORY_ATTRIBUTES_FAIL";
export const UPDATE_CATEGORY_ATTRIBUTES="UPDATE_CATEGORY_ATTRIBUTES";
export const UPDATE_CURRENT_PRODUCT_INVENTORY="UPDATE_CURRENT_PRODUCT_INVENTORY"

export const SET_SELECTED_CATEGORIES ="SET_SELECTED_CATEGORIES"
export const CLEAR_SELECTED_CATEGORIES ="CLEAR_SELECTED_CATEGORIES"


export const UPDATE_CURRENT_EDITING_PRODUCT ="UPDATE_CURRENT_EDITING_PRODUCT"
export const CLEAR_CURRENT_EDITING_PRODUCT ="CLEAR_CURRENT_EDITING_PRODUCT"