import React from "react";
import "./loader.scss"



const Loader = (props) => {


    return (
        <div className="c-loader">

            <div className="loader"></div>

        </div>
    )
}

export default Loader